<template>
    <div v-if="formData" id="print_area">
        <div class="row mb-2 pb-2 border-bottom border-info">
            <h4 class="col-sm-6 text-uppercase pt-2">
                Billing Invoice
            </h4>
            <div class="col-sm-6 text-right">
                <img src="images/logo-acc-transparent-50w.png" class="img-size-50">
                <span class="font-bold ml-1 font-size-18">PoS</span>
                <div class="text-bold">{{ app_website }}</div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <div class="font-size-18">Billed To</div>
                <div class="font-size-14">{{ formData.user.name }}</div>
                <div class="font-size-14">{{ formData.company.name }}</div>
                <div class="font-size-14">{{ formData.company.address }}</div>
                <div class="font-size-14">{{ formData.company.country.name }}</div>
                <div class="font-size-14">{{ formData.company.phone }}</div>
                <div class="font-size-14">{{ formData.company.email }}</div>
            </div>
            <div class="col-sm-6 text-right">
                <div class="font-size-18">
                    <span>Invoice #</span><span class="text-bold"> {{ formData.id }}</span>
                </div>
                <div class="font-size-14">
                    <span>Created:</span>
                    <span class="text-bold"> {{ formData.created_at }}</span>
                </div>
                <div class="font-size-14">
                    <span>Updated:</span>
                    <span class="text-bold"> {{ formData.updated_at }}</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered table-sm mt-2">
                        <thead>
                        <tr>
                            <th style="width: 20%;">Package</th>
                            <th>Description</th>
                            <th>Period</th>
                            <th class="text-right" style="width: 20%;">Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{formData.package.name}}</td>
                            <td>{{formData.package.description}}</td>
                            <td>{{formData.period}} day(s)</td>
                            <td class="text-right">${{ formData.due_amount }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-bold" colspan="3">Total</td>
                            <td class="text-right font-bold">${{ formData.due_amount }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                Payment Amount: <span class="text-bold">${{ formData.payment_amount }}</span><br />
                <div v-if="formData.payment_status === 1">
                    Payment Status: <span class="text-bold">Paid</span><br />
                    Payment Date: <span class="text-bold"> {{ formData.payment_date }}</span><br />
                    Payment Method: <span class="text-bold"> {{ getBillingPaymentMethodName(formData.payment_method) }}</span>
                </div>
                <div v-else>
                    Payment Status:<span class="text-bold"> Unpaid</span><br />
                </div>
                <div v-if="asOldBillingWalletTransactions.length > 0">Wallet Credited: <span class="text-bold">${{ wallet_credited }}</span></div>
                <div v-if="asNewBillingWalletTransactions.length > 0">Wallet Debited: <span class="text-bold">${{ wallet_debited }}</span></div>
            </div>
        </div>

        <button type="button" class="btn btn-sm btn-secondary mt-5 d-print-none" v-on:click="printDiv('print_area')"><i class="fas fa-print"></i></button>
    </div>
</template>

<script>
import Vue from 'vue';
import {printDiv} from "@/helpers/printHelper";
import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
import {getCurrencyFormattedVal} from "@/helpers/currencyHelper";
import {getBillingPaymentMethodName} from "@/helpers/billingHelper";
import {BillingService} from "@/services/billing-service";
let billingService = new BillingService();
import {OptionService} from "@/services/option-service";
let optionService = new OptionService();
export default {
    name: "BillingInvoice",
    data: function() {
        return {
            formData: {
                "id": null,
                "package_id": null,
                "period": null,
                "due_amount": null,
                "payment_date": null,
                "payment_amount": null,
                "payment_vat": null,
                "payment_tax": null,
                "payment_method": null,
                "payment_status": null,
                "payment_tran_id": null,
                "payment_name": null,
                "payment_address": null,
                "payment_email": null,
                "payment_phone": null,
                "payment_card_no": null,
                "payment_card_expiry": null,
                "payment_card_vcc": null,
                "user_id": null,
                "company_id": null,
                "status": 1,
                "created_at": null,
                "updated_at": null,
                "company": {
                    "id": null,
                    "name": null,
                    "address": null,
                    "country_id": null,
                    "email": null,
                    "phone": null,
                    "image": null,
                    "date_format": null,
                    "date_time_format": null,
                    "currency": null,
                    "currency_prefix": null,
                    "invoice_thermal_print": null,
                    "status": null,
                    "created_at": null,
                    "updated_at": null,
                    "package_id": null,
                    "country": {
                        "id": null,
                        "name": null,
                        "iso": null,
                        "dial_code": null,
                        "currency_name": null,
                        "currency_symbol": null,
                        "currency_code": null,
                        "created_at": null,
                        "updated_at": null
                    },
                },
                "package": {
                    "id": null,
                    "name": null,
                    "description": null,
                    "monthly_price": null,
                    "yearly_price": null,
                    "allow_purchase": null,
                    "permissions":null,
                    "featured": null,
                    "status": null,
                    "created_at": null,
                    "updated_at": null
                },
                "user": {
                    "id": null,
                    "role_id": null,
                    "company_id": null,
                    "name": null,
                    "email": null,
                    "permissions": null,
                    "stores": null,
                    "image": null,
                    "forgot_otp": null,
                    "forgot_otp_date": null,
                    "timezone": "UTC",
                    "editable": 0,
                    "status": 1,
                    "created_at": null,
                    "updated_at": null,
                }
            },
            options: [],
            asOldBillingWalletTransactions: [],
            asNewBillingWalletTransactions: [],
        }
    },
    props:{
        id:{
            type: Number,
            default: () => null
        },
    },
    computed: {
        app_website: function (){
            if(this.options.length > 0){
                return this.options.filter(x => x.op_key === 'app_website')[0].op_value;
            }
            return '';
        },
        wallet_credited: function () {
            let total = 0;
            for(let i = 0; i < this.asOldBillingWalletTransactions.length; i++){
                total += Number(this.asOldBillingWalletTransactions[i].amount);
            }
            return total;
        },
        wallet_debited: function () {
            let total = 0;
            for(let i = 0; i < this.asNewBillingWalletTransactions.length; i++){
                total += Number(this.asNewBillingWalletTransactions[i].amount);
            }
            return total;
        },
    },
    components: {},
    methods:{
        printDiv,
        getCurrencyFormattedVal,
        getBillingPaymentMethodName,
        loadSavedData: async function () {
            let loader = this.$loading.show();
            let response = await billingService.getBillingInvoice(this.id);
            if (response.isSuccess) {
                this.formData = response.billingInvoice;
                this.formData.payment_date = convertUTCToLocalWithFormat(response.billingInvoice.payment_date);
                this.formData.created_at = convertUTCToLocalWithFormat(response.billingInvoice.created_at);
                this.formData.updated_at = convertUTCToLocalWithFormat(response.billingInvoice.updated_at);
                this.asOldBillingWalletTransactions = response.asOldBillingWalletTransactions;
                this.asNewBillingWalletTransactions = response.asNewBillingWalletTransactions;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        loadOptions: async function () {
            let loader = this.$loading.show();
            let response = await optionService.getOptions();
            if (response.isSuccess) {
                //console.log(response);
                this.options = response.options;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        }
    },
    async mounted() {
        await this.loadSavedData();
        await this.loadOptions();
    }
}
</script>

<style scoped>

</style>