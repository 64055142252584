<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content pl-2 pr-2">
            <div class="card card-default card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#tab-invoices" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Invoices</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#tab-subscriptions" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Subscriptions</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#tab-wallet" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">Wallet</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade show active" id="tab-invoices" role="tabpanel" aria-labelledby="tab-invoices">
                            <CustomDataTable v-bind:tableDataUrl="invoices.tableDataUrl" v-bind:columns="invoices.columns" v-bind:searchFilters="invoices.searchFilters" :show-per-page="false" :show-search="false" v-bind:additionalButtons="invoices.additionalButtons" ref="invoicesTable"></CustomDataTable>
                        </div>
                        <div class="tab-pane fade" id="tab-subscriptions" role="tabpanel" aria-labelledby="tab-subscriptions">
                            <CustomDataTable v-bind:tableDataUrl="subscriptions.tableDataUrl" v-bind:columns="subscriptions.columns" v-bind:searchFilters="subscriptions.searchFilters" :show-per-page="false" :show-search="false" v-bind:additionalButtons="subscriptions.additionalButtons" ref="subscriptionsTable"></CustomDataTable>
                        </div>
                        <div class="tab-pane fade" id="tab-wallet" role="tabpanel" aria-labelledby="tab-wallet">
                            <div class="row">
                                <div class="col-sm-2">
                                    <div class="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
                                        <a class="nav-link active" id="vert-tabs-profile-tab" data-toggle="pill" href="#tab-wallet-balance" role="tab" aria-controls="tab-wallet-balance" aria-selected="false">Balance</a>
                                        <a class="nav-link" id="vert-tabs-home-tab" data-toggle="pill" href="#tab-wallet-transactions" role="tab" aria-controls="tab-wallet-transactions" aria-selected="true">Transactions</a>
                                    </div>
                                </div>
                                <div class="col-sm-10">
                                    <div class="tab-content" id="vert-tabs-tabContent">
                                        <div class="tab-pane fade text-left show active" id="tab-wallet-balance" role="tabpanel" aria-labelledby="tab-wallet-balance">
                                            <div class="mb-2">Current Wallet Balance</div>
                                            <div class="font-size-25">${{ wallet.balance }}</div>
                                        </div>
                                        <div class="tab-pane fade" id="tab-wallet-transactions" role="tabpanel" aria-labelledby="tab-wallet-transactions">
                                            <CustomDataTable v-bind:tableDataUrl="walletTransactions.tableDataUrl" v-bind:columns="walletTransactions.columns" v-bind:searchFilters="walletTransactions.searchFilters" :show-per-page="false" :show-search="false" v-bind:additionalButtons="walletTransactions.additionalButtons" ref="walletTransactionsTable"></CustomDataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- /.content -->

        <ModalGeneral :title="invoiceModal.title" size="modal-lg" :showModal="invoiceModal.showModal" :hideModal="hideInvoiceModal" v-if="invoiceModal.showModal">
            <div slot="modal-body">
                <billing-invoice v-bind:id="invoiceModal.id"></billing-invoice>
            </div>
        </ModalGeneral>
        <ModalGeneral :title="paymentModal.title" size="modal-lg" :showModal="paymentModal.showModal" :hideModal="hidePaymentModal" v-if="paymentModal.showModal">
            <div slot="modal-body">
                <billing-payment v-bind:id="paymentModal.id"></billing-payment>
            </div>
        </ModalGeneral>

        <ModalGeneral :title="subscriptionModal.title" size="modal-lg" :showModal="subscriptionModal.showModal" :hideModal="hideSubscriptionModal" v-if="subscriptionModal.showModal">
            <div slot="modal-body">
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import CustomDataTable from "../../../components/app/table/CustomDataTable";
    import {isAdmin} from "@/helpers/userHelper";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
    import {getBillingPaymentMethodName} from "@/helpers/billingHelper";
    import ModalGeneral from "@/components/app/dialog/ModalGeneral";
    import BillingInvoice from "@/components/app/billing/BillingInvoice";
    import BillingPayment from "@/components/app/billing/BillingPayment";
    import {BillingService} from "@/services/billing-service";
    import Vue from "vue";
    let billingService = new BillingService();
    export default {
        name: 'Billing_view',
        data: function(){
            return {
                pageName: 'Billing',
                segments: [
                    { link: false, text: 'Billing' },
                ],
                invoices:{
                    tableDataUrl: this.$globalSettings.api.endpoints.billing.billingInvoicesDatatable,
                    columns: [
                        {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                        {label: 'Created', name: 'created_at', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name])},
                        {label: 'Package', name: 'package_name', orderable: true},
                        {label: 'Price', name: 'due_amount', orderable: true, transform: ({data, name}) => '$' + data[name]},
                        {label: 'Payment', name: 'payment_amount', orderable: true, transform: ({data, name}) => '$' + data[name]},
                        {label: 'Method', name: 'payment_method', orderable: true, transform: ({data, name}) => getBillingPaymentMethodName(data[name])},
                        {label: 'Status', name: 'payment_status', orderable: true, transform: ({data, name}) => data[name] === 1? 'Paid': 'Due' },
                        {label: 'User', name: 'user_name', orderable: true}
                    ],
                    searchFilters:{
                        search: '',
                        length: 10,
                        column: 'created_at',
                        dir: 'desc'
                    },
                    additionalButtons: []
                },
                subscriptions:{
                    tableDataUrl: this.$globalSettings.api.endpoints.billing.billingSubscriptionsDatatable,
                    columns: [
                        {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                        {label: 'Start Date', name: 'date_start', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name])},
                        {label: 'Expiration', name: 'date_end', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name])},
                        {label: 'Invoice #', name: 'billing_invoice_id', orderable: true},
                        {label: 'Package', name: 'package_name', orderable: true},

                    ],
                    searchFilters:{
                        search: '',
                        length: 10,
                        column: 'date_end',
                        dir: 'desc'
                    },
                    additionalButtons: []
                },
                walletTransactions:{
                    tableDataUrl: this.$globalSettings.api.endpoints.billing.billingWalletTransactionsDatatable,
                    columns: [
                        {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                        {label: 'Date', name: 'created_at', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name])},
                        {label: 'Amount', name: 'amount', orderable: true, style: 'text-align: right;', transform: ({data, name}) => '$'+data[name]},
                        {label: 'Type', name: 'type', orderable: true, transform: ({data, name}) => data[name] === 1? 'In': 'Out', style: 'text-align: right;'},
                        {label: 'Invoice', name: 'billing_invoice_id', orderable: true, style: 'text-align: right;'},
                        {label: 'Description', name: 'description', orderable: false},

                    ],
                    searchFilters:{
                        search: '',
                        length: 10,
                        column: 'created_at',
                        dir: 'desc'
                    },
                    additionalButtons: []
                },
                wallet:{
                    balance: 0,
                },
                invoiceModal:{
                    title: 'Invoice for Subscription',
                    showModal: false,
                    id: null,
                },
                subscriptionModal:{
                    title: 'Subscription',
                    showModal: false,
                    id: null,
                },
                paymentModal:{
                    title: 'Make Payment',
                    showModal: false,
                    id: null,
                },
            }
        },
        props: {},
        components: {
            BillingPayment,
            BillingInvoice,
            ModalGeneral,
            BreadCrumb, CustomDataTable
        },
        computed: {

        },
        methods: {
            async loadBillingWalletBalance() {
                let loader = this.$loading.show();
                let response = await billingService.getBillingWalletBalance();
                if (response.isSuccess) {
                    this.wallet.balance = response.billingWalletBalance;
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            async deleteInvoice(data){
                if(confirm("Are you sure that you want to delete this invoice?")) {
                    let loader = this.$loading.show();
                    let response = await billingService.deleteInvoice(data.id);
                    if (response.isSuccess) {
                        await this.$refs.invoicesTable.loadTable();
                        await this.$refs.subscriptionsTable.loadTable();
                        await this.$refs.walletTransactionsTable.loadTable();
                        await this.loadBillingWalletBalance();
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            },
            showInvoiceModal(data){
                this.invoiceModal.id = data.id;
                this.invoiceModal.showModal = true;
            },
            hideInvoiceModal(){
                this.invoiceModal.showModal = false;
            },
            showSubscriptionModal(data){
                this.subscriptionModal.id = data.id;
                this.subscriptionModal.showModal = true;
            },
            hideSubscriptionModal(){
                this.subscriptionModal.showModal = false;
            },
            showPaymentModal(data){
                this.paymentModal.id = data.id;
                this.paymentModal.showModal = true;
            },
            hidePaymentModal(){
                this.paymentModal.showModal = false;
            },
        },
        async mounted() {
            if (isAdmin()) {
                this.invoices.columns.push({
                    label: '',
                    style: 'width: 60px',
                    component: CustomDataTableDropDownButton,
                    meta: {
                        items: [
                            {
                                label: 'View',
                                showLabel: true,
                                iconClass: 'fas fa-search',
                                event: "click",
                                handler: this.showInvoiceModal,
                                dataPermissionConditions: [],
                                conditions: [],
                            },
                            {
                                label: 'Pay Now',
                                showLabel: true,
                                iconClass: 'fas fa-credit-card',
                                event: "click",
                                handler: this.showPaymentModal,
                                dataPermissionConditions: [{column: 'payment_status', val: 0}],
                                conditions: [],
                            },
                            {
                                label: 'Delete Invoice',
                                showLabel: true,
                                iconClass: 'far fa-trash-alt',
                                event: "click",
                                handler: this.deleteInvoice,
                                dataPermissionConditions: [{column: 'payment_status', val: 0}],
                                conditions: [],
                            },
                        ]
                    }
                });
                this.subscriptions.columns.push({
                    label: '',
                    style: 'width: 60px',
                    component: CustomDataTableDropDownButton,
                    meta: {
                        items: [
                            /*{
                                label: 'View',
                                showLabel: true,
                                iconClass: 'fas fa-search',
                                event: "click",
                                handler: this.showSubscriptionModal,
                                dataPermissionConditions: [],
                                conditions: [],
                            }*/
                        ]
                    }
                });

                await this.loadBillingWalletBalance();
            }

        }
    }
</script>

<style scoped>

</style>
