import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";

export class OptionService{
    //formDataCompany = {};
    constructor() {
        //this.formDataCompany = {};
    }

    async getOptions(){
        let options = Vue.prototype.$store.getters['common/options'];
        if(options.length <= 0){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.options)
                .then((response) => {
                    console.log(response.data);
                    Vue.prototype.$store.dispatch('common/setOptions', {value: response.data.options});
                    return {isSuccess: true, message: '', options: response.data.options};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', options: []};
                })
        }
        return {isSuccess: true, message: '', options: options};
    }
}