<template>
    <section class="content pl-2 pr-2">
        <div class="row mb-4">
            <div class="col-sm-4">
                <div>
                    <span>Invoice #</span> <span class="text-bold">{{ formData.id }}</span>
                </div>
                <div>
                    <span>Package:</span> <span class="text-bold">{{formData.package.name}}</span>
                </div>
                <div>
                    <span>Period:</span> <span class="text-bold">{{formData.period}} days</span>
                </div>
                <div>
                    <span>Amount to Pay:</span> <span class="text-bold">${{ formData.payment_amount }}</span>
                </div>
                <div>
                    <span>Created:</span>
                    <span class="text-bold"> {{ formData.created_at }}</span>
                </div>
            </div>
            <div class="col-sm-8">
                <p>Online payment is currently unavailable.</p>
                <p><i>Please email us at <a :href="'mailto:'+app_email">{{ app_email }}</a> to get further instructions on making payment for your invoice.</i></p>
            </div>
        </div>

    </section>
</template>

<script>
import Vue from 'vue';
import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
import {BillingService} from "@/services/billing-service";
let billingService = new BillingService();
import {OptionService} from "@/services/option-service";
let optionService = new OptionService();
export default {
    name: "BillingPayment",
    data: function() {
        return {
            formData: {
                "id": null,
                "package_id": null,
                "period": null,
                "due_amount": null,
                "payment_date": null,
                "payment_amount": null,
                "payment_vat": null,
                "payment_tax": null,
                "payment_method": null,
                "payment_status": null,
                "payment_tran_id": null,
                "payment_name": null,
                "payment_address": null,
                "payment_email": null,
                "payment_phone": null,
                "payment_card_no": null,
                "payment_card_expiry": null,
                "payment_card_vcc": null,
                "user_id": null,
                "company_id": null,
                "status": 1,
                "created_at": null,
                "updated_at": null,
                "company": {
                    "id": null,
                    "name": null,
                    "address": null,
                    "country_id": null,
                    "email": null,
                    "phone": null,
                    "image": null,
                    "date_format": null,
                    "date_time_format": null,
                    "currency": null,
                    "currency_prefix": null,
                    "invoice_thermal_print": null,
                    "status": null,
                    "created_at": null,
                    "updated_at": null,
                    "package_id": null,
                    "country": {
                        "id": null,
                        "name": null,
                        "iso": null,
                        "dial_code": null,
                        "currency_name": null,
                        "currency_symbol": null,
                        "currency_code": null,
                        "created_at": null,
                        "updated_at": null
                    },
                },
                "package": {
                    "id": null,
                    "name": null,
                    "description": null,
                    "monthly_price": null,
                    "yearly_price": null,
                    "allow_purchase": null,
                    "permissions":null,
                    "featured": null,
                    "status": null,
                    "created_at": null,
                    "updated_at": null
                },
                "user": {
                    "id": null,
                    "role_id": null,
                    "company_id": null,
                    "name": null,
                    "email": null,
                    "permissions": null,
                    "stores": null,
                    "image": null,
                    "forgot_otp": null,
                    "forgot_otp_date": null,
                    "timezone": "UTC",
                    "editable": 0,
                    "status": 1,
                    "created_at": null,
                    "updated_at": null,
                }
            },
            options: [],
        }
    },
    props:{
        id:{
            type: Number,
            default: () => null
        },
    },
    computed: {
        app_email: function (){
            if(this.options.length > 0){
                return this.options.filter(x => x.op_key === 'app_email')[0].op_value;
            }
            return '';
        },
    },
    components: {},
    methods:{
        loadSavedData: async function () {
            let loader = this.$loading.show();
            let response = await billingService.getBillingInvoice(this.id);
            if (response.isSuccess) {
                this.formData = response.billingInvoice;
                this.formData.payment_date = convertUTCToLocalWithFormat(response.billingInvoice.payment_date);
                this.formData.created_at = convertUTCToLocalWithFormat(response.billingInvoice.created_at);
                this.formData.updated_at = convertUTCToLocalWithFormat(response.billingInvoice.updated_at);
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        loadOptions: async function () {
            let loader = this.$loading.show();
            let response = await optionService.getOptions();
            if (response.isSuccess) {
                //console.log(response);
                this.options = response.options;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        }
    },
    async mounted() {
        await this.loadSavedData();
        await this.loadOptions();
    }
}
</script>

<style scoped>

</style>